import { H1 } from 'common/styles/Headings.styled';
import styled from 'styled-components';
import Header from 'components/Header/Header';
import { Button } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { GetStaticProps } from 'next';
import { getTranslations } from 'common/i18n/translation';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const LayoutContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  padding: 24px;

  /* Desktop */
  @media screen and (min-width: 1200px) {
    padding: 40px;
  }
`;

const ErrorPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
`;

const Content = styled.div`
  padding: 20px;
`;

const Image = styled.div`
  width: 100%;
  max-width: 800px;
  height: 200px;
  background: url('/img/error.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  /* Desktop */
  @media screen and (min-width: 1024px) {
    height: 450px;
  }
`;

const H1Styled = styled(H1)`
  margin: 24px 0;
`;

export const getStaticProps: GetStaticProps = async (context) => {
  const locale = context.locale;

  return {
    props: {
      ...(await getTranslations(locale))
    }
  };
};


export default function NotFound() {
  const { t } = useTranslation('main');

  return (
    <Container>
      <LayoutContainer>
        <Header showBreadcrumbs={false} />
        <ErrorPage>
          <Content>
            <Image />
            <H1Styled> {t('error.notFound')}</H1Styled>
            <a href="https://www.digitalhouse.com">
               <Button variant="contained" size="large" disableElevation>
                   {t('error.returnDigitalHouse')}
              </Button>
            </a>
          </Content>
        </ErrorPage>
      </LayoutContainer>
    </Container>
  );
}
